<template>
  <div>
    <div class="pc">
      <div class="container padding-top-60">
        <div class="subtitle2">구매를 완료하세요.</div>
        <div class="h7 main title-1">앱 마켓 팩/플러그인 구매</div>
        <div class="item">
          <div class="col-6">
            <div class="subtitle5 main">상품 <span class="body2 primary" v-if="order.order_product.is_subscription">(월결제)</span></div>
            <div v-if="!userService.service_order.trial"
                 class="body2 sub3">청구시작일 <span class="body2-medium sub2">{{ datesFormat(order.next_date, 'date_3') }}</span></div>
          </div>
          <div class="col-3 body2 sub">{{ order.order_product.product.name }}</div>
          <div class="col-3 body4 sub" style="text-align: right">
            <span class="body2-bold main">
              <span v-if="order.order_product.product.category2_name==='플랫폼 팩'">
                {{ order.order_product.product_price | currencyNum }}
              </span>
              <span v-else>{{ order.order_product.required_option_price|currencyNum }}</span>
              <span class="body4">
                <span class="sub">원
                  <span class="sub3" v-if="order.order_product.product.category2_name==='플랫폼 팩'">
                    /{{ order.order_product.date_unit }}개월
                  </span>
                  <span class="sub3" v-else-if="order.order_product.is_subscription">/월</span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div class="item" v-if="order.order_product.product.selectors[0].name==='케어서비스' && order.order_product.product.selectors[0].options[0].selected">
          <div class="col-6">
            <div class="subtitle5 main">케어서비스</div>
            <div class="body2 sub3">전화상담 후 충전금에서 차감</div>
          </div>
          <div class="col-3 body2 sub">전문가 확인 신청</div>
          <div class="col-3 body4 sub" style="text-align: right"><span class="body2-bold main">{{ order.order_product.add_option_price|currencyNum }}
            <span class="body4"><span class="sub">원<span class="sub3">/월</span></span></span></span></div>
        </div>
        <div class="item">
          <div class="col-6 subtitle4 main">총 결제금액</div>
          <div class="col-6" style="text-align:right">
            <div style="text-align: right"><span class="h6 main">{{ totalPrice | currencyNum }}
            <span class="body2-medium"><span class="sub">원
              <span class="sub3" v-if="order.order_product.product.category2_name==='플랫폼 팩'">
                /{{ order.order_product.date_unit }}개월
              </span>
              <span class="sub3" v-else-if="order.order_product.is_subscription">/월</span></span></span></span></div>
          </div>
        </div>
        <div class="box-info">
          <template v-if="userService.service_order.trial">
            <li>무료체험 기간({{ datesFormat(userService.service_order.end_date, 'date_3') }})동안 무료로 사용할 수 있습니다.</li>
            <li>서비스 플랜을 결제하실 때, 해당 구매내역을 함께 결제하거나 제외하실 수 있습니다.</li>
          </template>
          <template v-else>
            <li>결제 이후에는 청약철회권을 행사할 수 없습니다. 취소와 환불에 관해 자세히 알아보기.</li>
          </template>
          <li>모든 금액은 부가가치세를 제외한 금액입니다. 계속 진행함으로써 본인이 만 19세 이상임을 확인하고, 본 약관에 동의함을 확인합니다.</li>
          <li>계속 진행하면 런치팩 <a href="https://platformfy.com/term" target="_blank">서비스 약관</a>
            에 동의하는 것으로 간주됩니다. <a href="https://platformfy.com/privacy" target="_blank">개인정보처리방침</a>
            - 개인정보의 처리 및 보유 기간에 사용자의 데이터를 처리하는 방식이 설명되어 있습니다.</li>
        </div>

        <div class="h7 main title-1">결제수단</div>
        <div class="item">
          <div style="display:grid;grid-template-columns: 160px 1fr;grid-gap:12px">
            <div class="subtitle5 main">결제카드 정보</div>
            <div>
              <div class="body3 sub">{{ $store.getters.userService.bill }}</div>
              <div class="body3 sub3">*결제수단 변경은 관리자 사이트에서 가능합니다.</div>
            </div>
          </div>
        </div>
        <div class="item"></div>
        <div class="flex-center">
          <div class="btns">
            <button class="button is-gray" @click="$router.back()">이전</button>
            <button class="button is-primary" @click="clickAccount">구매하기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ServiceAdminAPIMixin from "../../mixins/ServiceAdminAPIMixin";
  import moment from 'moment'

  export default {
    name: "MarketAccount",
    mixins: [
      ServiceAdminAPIMixin
    ],
    created() {
      let next_date = this.userService.service_order.next_date;
      if(next_date) {
        next_date = next_date.split(' ')[0];
      } else {
        //next_date = this.userService.service_order.end_date;
        next_date = moment().add(this.order.order_product.date_unit, 'months').format('YYYY-MM-DD');
      }
      this.order.next_date = next_date;
      this.$store.commit('setBasket', this.order);
    },
    data() {
      return {}
    },
    computed: {
      order() {
        return this.$store.getters.basket;
      },
      isFree() {
        /*let now = this.dayjs(this.dayjs().format('YYYY-MM-DD'))
        let free_date = this.dayjs(this.userService.service_order.free_date)
        let diff = free_date.diff(now,'days')
        return diff >= 0*/
        return this.userService.service_order.trial;
      },
      totalPrice() {
        return this.isFree ? 0 : this.order.order_product.total_price;
      }
    },
    methods: {
      clickAccount() {
        let order_product = this.order.order_product;
        let subscribe_price = this.order.subscribe_price + this.order.subscribe_price * 0.1;
        let params = {
          next_date: this.order.next_date,
          subscribe_price: subscribe_price,
          pay_type: 1,
          product: {
            product_id: order_product.product.id,
            quantity: 1,
            discount_price: order_product.product.price.discount_price,
            discount_rate: order_product.product.price.discount_rate,
            total_price: order_product.total_price,
            product_price: order_product.product_price,
            is_subscription: this.order.order_product.is_subscription
          },
          date_unit: order_product.date_unit,
          is_subscription: this.order.order_product.is_subscription
        }

        this.setLoading('구매 진행 중입니다');
        this.request.serviceAdmin.post(`launchpack/v1/service/${this.userService.id}/app_order`, params).then(res=>{
          if(res.status === 200)
            this.request.serviceAdmin.get(`launchpack/service/${this.userService.id}`).then(res=> {
              this.clearLoading();
              this.$store.commit('setUserService', res.data);
              this.$router.push('account_done');
            }).catch(()=>{
              this.clearLoading();
            });
        }).catch(()=>{
          this.clearLoading();
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .title-1
    border-top 1px solid $main
    padding 16px 0
    margin-top 40px
  .item
    border-top 1px solid $gray2
    padding 24px 0
    display flex
    align-items center
  .box-info
    padding 24px
    border-radius 8px
    background-color $gray4
    li
      font-size 15px
      line-height 24px
      color $sub2
      text-indent -20px
      padding-left 24px

  .btns
    margin 40px auto
    display grid
    grid-template-columns repeat(2, 242px)
    grid-column-gap 20px
</style>
